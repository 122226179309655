<script setup>
import { ref } from 'vue';
import ExclamationCircleIcon from './Icons/ExclamationCircleIcon.vue';

defineProps({
    message: {
        type: String,
        default: ''
    },
    type: {
        type: String,
        default: 'danger'
    }
});

const textColor = ref({
    danger: 'text-red-600',
    warning: 'text-orange-500',
    info: 'text-blue-500'
})
</script>

<template>
    <div v-show="message">
        <p :class="[textColor[type]]" class="flex items-center pt-2 text-sm">
            <ExclamationCircleIcon class="w-4 h-4" />
            <span class="ml-1 text-xs leading-4">
                {{ message }}
            </span>
        </p>
    </div>
</template>
